import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit {

  constructor(
    private router: Router,
  ) {}

  ngOnInit() {

    var footerPos = $(".footer-wrapper").offset();

    $(window).scroll(function () {

      if($('.bottom-menu:visible').length == 1) {
        $(".bottom-menu").toggle();
      }

      var scrollPos = $(window).scrollTop() + $(window).height();

      if( scrollPos > footerPos) {
        $(".bottom-menu-btn").css('display',"none");
      }
      else {
        $(".bottom-menu-btn").css('display',"block");
      }
    });

    $(".content-section").on('click',function(){
      if($('.bottom-menu:visible').length == 1) {
        $(".bottom-menu").toggle();
      };
    });

  }

}
