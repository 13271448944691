import { Component, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { Router } from '@angular/router';

import * as $ from "jquery";
declare var $: any;

@Component({
  selector: 'app-pricing-page',
  templateUrl: './pricing-page.component.html',
  styleUrls: ['./pricing-page.component.scss']
})
export class PricingPageComponent implements OnInit {

  constructor(
    private titleService: Title,
    private router: Router
  ) {
    this.titleService.setTitle("Pricing - ZERO BrandCard™");
  }

  ngOnInit() {
    $(".section-title").html('PRICING');

    $(window).scroll(function () {
      var sec1Pos = $(".sec-1").offset().top;
      var sec1Height = $(".sec-1").outerHeight(true);
      var sec2Pos = $(".sec-2").offset().top;
      var sec2Height = $(".sec-2").outerHeight(true);
      var sec3Pos = $(".sec-3").offset().top;
      var sec3Height = $(".sec-3").outerHeight(true);

      var scrollPos = $(window).scrollTop() + 60;

      if (scrollPos > sec1Pos && scrollPos < sec1Pos + sec1Height) {
        $(".section-title").html('PRICING');
      }
      else if (scrollPos > sec2Pos && scrollPos < sec2Pos + sec2Height) {
        $(".section-title").html('HOW IT WORKS');
      }
      else if (scrollPos > sec3Pos && scrollPos < sec3Pos + sec3Height) {
        $(".section-title").html('FAQ');
      }
    });
  }

  goFAQ() {
    this.router.navigate(['/faq'])
  }

  goRegistry() {
    this.router.navigate(['/register'])
  }

  goDownload() {
    window.open("https://web.codezero.app/appdownload", "_blank");
  }

  goAppStore() {
    window.open("https://itunes.apple.com/my/app/zero-brandcard/id1433549946?mt=8&utm_source=ios&utm_medium=webpage&utm_campaign=zeroweb", "_blank");
  }

  goGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=tech.zero.app2&utm_source=android&utm_medium=webpage&utm_campaign=zeroweb", "_blank");
  }

  goAppGallery() {
    window.open("https://appgallery.huawei.com/app/C106256461", "_blank");
  }

  wcTutorial() {
    window.open("https://drive.google.com/file/d/17fVjBNPkU8YHZN9yeRn0VZyrQYdbyZoh/view?usp=sharing", "_blank");
  }

  bcTutorial() {
    window.open("https://drive.google.com/file/d/1YPLQ84W-1Qhp3_DzP5BwP3jb0mhxoMsO/view?usp=sharing", "_blank");
  }

  downloadTutorial() {
    window.open("https://drive.google.com/file/d/1_MDcFn7P9jBeF0HmFBx3q3eDjFui9_gM/view?usp=sharing", "_blank");
  }
}
