import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor() {

  }

  ngOnInit() {

    $(".bottom-menu-btn").hover(function(){
      $(this).find("span").css("visibility","visible");
    },function() {
      $(this).find("span").css("visibility","hidden");
    });

  }

  toggleMenu() {
    $(".bottom-menu").toggle();
    $(".menu-title").toggle();
  }

}
