import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

import * as $ from "jquery";
declare var $: any;

@Component({
  selector: 'app-home-page-m',
  templateUrl: './home-page-m.component.html',
  styleUrls: ['./home-page-m.component.scss']
})

export class HomePageMComponent implements OnInit {
  featureDefault = true;
  featureShare = false;
  featureExchange = false;
  featureEcho = false;
  featureFeed = false;
  featureFeaturedContent = false;
  featureSearch = false;
  featureScan = false;
  featureContact = false;
  featureFollowUps = false;
  featureVisitorRegistry = false;
  featureBlackBox = false;
  featureHRMS = false;
  showNavigationArrows = false;
  showNavigationIndicators = false;


  constructor(
    private router: Router,
    private config: NgbCarouselConfig,
  ) {
    config.showNavigationArrows = false;
    config.showNavigationIndicators = true;
  }

  ngOnInit() {
    $(".discover-icons").on('click', function () {

      if ($(".discover-icons").find(".active")) {
        $(".discover-icons.active").find(".discover-btn-desc").toggleClass("font-visible");
        $(".discover-icons.active").find("img").toggle();
        $(".discover-icons.active").removeClass("active");
      }

      $(this).addClass("active");
      $(this).find(".discover-btn-desc").toggleClass("font-visible");
      $(this).find("img").toggle();
    });

  }

  toRight() {
    $('.discover-btns').animate({ scrollLeft: "+=100px" }, "slow");
  }

  toLeft() {
    $('.discover-btns').animate({ scrollLeft: "-=100px" }, "slow");
  }

  goAppStore() {
    window.open("https://itunes.apple.com/my/app/zero-brandcard/id1433549946?mt=8&utm_source=ios&utm_medium=mobileweb&utm_campaign=zeroweb", "_blank");
  }

  goGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=tech.zero.app2&utm_source=android&utm_medium=mobileweb&utm_campaign=zeroweb", "_blank");
  }

  goAppGallery() {
    window.open("https://appgallery.huawei.com/app/C106256461", "_blank");
  }

  goZeroBrandCard() {
    window.open("https://businesscard.zerobrandcard.com/", "_blank");
  }

  goPricing() {
    this.router.navigate(['/m/pricing'])
  }


  goHowItWorks() {
    this.router.navigate(['/m/pricing'], { fragment: 'HowItWorks' })
  }

  displayFeature(itm) {
    this.featureDefault = false;
    this.featureShare = false;
    this.featureExchange = false;
    this.featureEcho = false;
    this.featureFeed = false;
    this.featureFeaturedContent = false;
    this.featureSearch = false;
    this.featureScan = false;
    this.featureContact = false;
    this.featureFollowUps = false;
    this.featureVisitorRegistry = false;
    this.featureBlackBox = false;
    this.featureHRMS = false;

    if (itm === 'Share') {
      this.featureShare = true;
    }
    if (itm === 'Exchange') {
      this.featureExchange = true;
    }
    if (itm === 'Echo') {
      this.featureEcho = true;
    }
    if (itm === 'Feed') {
      this.featureFeed = true;
    }
    if (itm === 'FeaturedContent') {
      this.featureFeaturedContent = true;
    }
    if (itm === 'Search') {
      this.featureSearch = true;
    }
    if (itm === 'Scan') {
      this.featureScan = true;
    }
    if (itm === 'Contact') {
      this.featureContact = true;
    }
    if (itm === 'FollowUps') {
      this.featureFollowUps = true;
    }
    if (itm === 'VisitorRegistry') {
      this.featureVisitorRegistry = true;
    }
    if (itm === 'BlackBox') {
      this.featureBlackBox = true;
    }
    if (itm === 'HRMS') {
      this.featureHRMS = true;
    }
  }
}
