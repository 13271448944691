import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import Swal from 'ngx-angular8-sweetalert2';
import { HttpClient } from '@angular/common/http';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { Title } from '@angular/platform-browser';
import { SearchCountryField, CountryISO,PhoneNumberFormat } from 'ngx-intl-tel-input';


import * as $ from "jquery";
declare var $: any;

@Component({
  selector: 'app-registry',
  templateUrl: './registry.component.html',
  styleUrls: ['./registry.component.scss']
})
export class RegistryComponent implements OnInit {
  subscriptionForm: FormGroup;
  registrationForm: FormGroup;
  loading = false;
  apiurl = environment.apiUrl;
  isSubmit = false;
  isChecked = false;
  isShow = false;
  separateDialCode = false;

  countryselected = 'my';
  countryselected2 = 'my';
  preferredCountries = [CountryISO.Malaysia, CountryISO.Singapore, CountryISO.UnitedStates];
  PhoneNumberFormat = PhoneNumberFormat;
  onlycountries = [];

  constructor(
    private fB: FormBuilder,
    private router: Router,    
    public http: HttpClient,
    private auth: AuthGuard,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Register - ZERO BrandCard™");

    this.subscriptionForm = this.fB.group({
      co_reg: ['RM 100'],
      usr_no: [1, [Validators.required, Validators.min(1)]],
      year_fee: ['RM 0'],
      total: ['RM 100']
    });
    this.registrationForm = this.fB.group({
      co_name: [null, Validators.required],
      name: [null, Validators.required],
      mobile: [null, [Validators.required, /*Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")*/]],
      email: [null, [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      isChecked: [false, Validators.requiredTrue]
    });
  }

  ngOnInit() {
    $(".section-title").html('REGISTRATION');
    this.calculateTotal();
  }

  get formSubError() {
    return this.subscriptionForm.controls;
  }

  get formRegError() {
    return this.registrationForm.controls;
  }

  toggleShow() {
    this.isShow = !this.isShow;
  }

  goFAQ() {
    if (this.auth.isMobile()) {
      this.router.navigate(['/m/faq'])
    } else {
      this.router.navigate(['/faq'])
    }
  }

  calculateTotal() {
    const usrNo = this.subscriptionForm.get('usr_no').value
    const totalusr = usrNo * 36
    const total = totalusr + 100;
    this.subscriptionForm.patchValue({
      year_fee: 'RM ' + Number(totalusr).toLocaleString('en-GB'),
      total: 'RM ' + Number(total).toLocaleString('en-GB'),
    })
  }

  getIsChecked() {
    this.isChecked = !this.isChecked
  }

  submitRegister() {
    this.isSubmit = true;

    if (this.registrationForm.invalid || this.subscriptionForm.invalid) {
      this.loading = false;
      return;
    }

    this.loading = true;
    let url = this.apiurl + "/v3/landing/company/register/trial";
    const form = this.registrationForm.value;
    const form2 = this.subscriptionForm.value;

    const mobile = form.mobile;
    let mobile1 = mobile.internationalNumber.replace(/\s/g, '').replace(/[+_-]/g,'');//.replace('+', '').replace
       
    let ccode = mobile.countryCode;

    const params = {
      name: form.name,
      email: form.email,
      mobile: mobile1,
      company: form.co_name,
      passwd: form.password,
      no_seats: form2.usr_no,
      country: ccode
    }
    
    this.http.post(url, params).subscribe((res: any) => {
      if (res.status == "OK") {
        this.loading = false;
        this.isSubmit = false;
        this.registrationForm.reset();
        if (this.auth.isMobile()) {
          this.router.navigate(['/m/complete-register'])
        } else {
          this.router.navigate(['/complete-register'])
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops!',
          html: res.message,
          confirmButtonColor: '#7DEDBB',
          allowOutsideClick: false
        }).then((result) => {
          this.loading = false;
        });
      }
    });

  }


}
