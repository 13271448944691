import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-terms-m',
  templateUrl: './terms-m.component.html',
  styleUrls: ['./terms-m.component.scss']
})
export class TermsMComponent implements OnInit {

  constructor() {
    // $(window).on('load resize',function(){
    //   if($(window).width() > 768){
    //     window.location.href = "/terms";
    //   }
    // });
   }

  ngOnInit() {
  }

  goHome() {
    window.open("/m/home","_self");
  }

}
