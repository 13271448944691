import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ctos-verified',
  templateUrl: './ctos-verified.component.html',
  styleUrls: ['./ctos-verified.component.scss']
})
export class CtosVerifiedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  goWhatsapp() {
    window.open('Https://wa.me/60183503848', '_blank');
  }

  setUp() {
    window.open('https://businesscard.zerobrandcard.com', '_blank');
  }

  contactUs() {
    window.open('/visitor-registry#ContactUs');
  }

  goWebsite() {
    window.open('https://codezero.app/',"_blank");
  }

  goHome() {
    window.open("https://zerobrandcard.com","_self")
  }

  toggleMenu() {
    /*$('.menu-toggle').toggle();

    if ($(".toggle-bg").is(':visible')) {
      $("body").css("overflow-y", "hidden");
    }
    else {
      $("body").css("overflow-y", "scroll");
    }*/
  }

  goLogin() {
    window.open("https://businesscard.zerobrandcard.com","_blank");
  }
}
