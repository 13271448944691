import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule }    from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DesktopModule } from './desktop/desktop.module';
import { MobileModule } from './mobile/mobile.module';

//import { LandingPageComponent } from './desktop/layout/landing-page/landing-page.component';


import { VisitorLandingPageComponent } from './desktop/pages/visitor-landing-page/visitor-landing-page.component';
import { PricingPageComponent } from './desktop/pages/pricing-page/pricing-page.component';
import { CtosVerifiedComponent } from './desktop/pages/ctos-verified/ctos-verified.component';
import { CtosLandingPageComponent } from './desktop/pages/ctos-landing-page/ctos-landing-page.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    VisitorLandingPageComponent,
    PricingPageComponent,
    CtosVerifiedComponent,
    CtosLandingPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DesktopModule,
    MobileModule,
    BrowserAnimationsModule,
    NgxIntlTelInputModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
