import { Component } from '@angular/core'

@Component({
  selector: 'app-ctos-landing-page',
  templateUrl: './ctos-landing-page.component.html',
  styleUrls: ['./ctos-landing-page.component.scss']
})

export class CtosLandingPageComponent {
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  toggleMenu() {
    /*$('.menu-toggle').toggle();

    if ($(".toggle-bg").is(':visible')) {
      $("body").css("overflow-y", "hidden");
    }
    else {
      $("body").css("overflow-y", "scroll");
    }*/
  }

  goWebsite() {
    window.open('https://codezero.app/',"_blank");
  }

  goLogin() {
    window.open("https://businesscard.zerobrandcard.com","_blank")
  }

  verifyCompany() {
    window.open('https://businesscard.zerobrandcard.com', '_blank')
  }
}