import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import * as $ from "jquery";
import { AuthGuard } from 'src/app/core/guards/auth.guard';
declare var $: any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(
    private titleService: Title,
    private router: Router,
    private auth: AuthGuard

  ) {
    this.titleService.setTitle("FAQ - ZERO BrandCard™");
  }

  ngOnInit() {
    $(".question-answer").on('click', function () {
      $(".collapse").on('show.bs.collapse', function () {
        $(this).parent().find(".faq-question-img img").addClass("rotate-img");
      });

      $(".collapse").on('hide.bs.collapse', function () {
        $(this).parent().find(".faq-question-img img").removeClass("rotate-img");
      });
    })

    $(".section-title").html('FAQ');
  }

  goPrivacy() {
    window.open("/privacy", "_blank");
  }

  goPricing() {
    if (this.auth.isMobile()) {
      this.router.navigate(['/m/pricing'])
    } else {
      this.router.navigate(['/pricing'])
    }
  }
  goRegister() {
    if (this.auth.isMobile()) {
      this.router.navigate(['/m/register'])
    } else {
      this.router.navigate(['/register'])
    }
  }

  goContactUs() {
    if (this.auth.isMobile()) {
      this.router.navigate(['/m/contact-us'])
    } else {
      this.router.navigate(['/contact-us'])
    }
  }

  goAppStore() {
    window.open("https://itunes.apple.com/my/app/zero-brandcard/id1433549946?mt=8&utm_source=ios&utm_medium=webpage&utm_campaign=zeroweb", "_blank");
  }

  goGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=tech.zero.app2&utm_source=android&utm_medium=webpage&utm_campaign=zeroweb", "_blank");
  }

  goAppGallery() {
    window.open("https://appgallery.huawei.com/app/C106256461", "_blank");
  }

  downloadTutorial() {
    window.open("https://drive.google.com/file/d/1_MDcFn7P9jBeF0HmFBx3q3eDjFui9_gM/view?usp=sharing", "_blank");
  }
}
