import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-privacy-m',
  templateUrl: './privacy-m.component.html',
  styleUrls: ['./privacy-m.component.scss']
})
export class PrivacyMComponent implements OnInit {

  constructor() {
    // $(window).on('load resize', function () {
    //   if ($(window).width() > 768) {
    //     window.location.href = "/privacy";
    //   }
    // });
  }

  ngOnInit() {
  }

  goHome() {
    window.open("/m/home", "_self");
  }

}
