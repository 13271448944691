import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
  ) {

  }

  ngOnInit() {
  }

  goZeroBrandCard() {
    window.open("https://businesscard.zerobrandcard.com", "_blank")
  }

  goContactUs() {
    this.router.navigate(['/contact-us'])
  }

  goRegistry() {
    this.router.navigate(['/register'])
  }

  goPricing() {
    this.router.navigate(['/pricing'])
  }

  goWorks() {
    this.router.navigate(['/pricing'], { fragment: 'HowItWorks' })
  }

  goFAQ() {
    this.router.navigate(['/faq'])
  }

  goAboutUs() {
    this.router.navigate(['/about-us'])
  }

  goMissionVision() {
    this.router.navigate(['/about-us'], { fragment: 'MissionVision' })
  }

  goPartners() {
    this.router.navigate(['/about-us'], { fragment: 'Partners' })
  }

  goPress() {
    this.router.navigate(['/about-us'], { fragment: 'Press' })
  }

  goHome() {
    this.router.navigate(['/'])
  }

  goDigital() {
    this.router.navigate(['/'], { fragment: 'GoDigital' })
  }

  goDynamic() {
    this.router.navigate(['/'], { fragment: 'TrulyDynamic' })
  }

  goFully() {
    this.router.navigate(['/'], { fragment: 'FullyDigital' })
  }

  goPrivacy() {
    this.router.navigate(['/privacy'])
  }

  goTerms() {
    this.router.navigate(['/terms'])
  }
}
