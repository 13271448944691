import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
  }

  goZeroBrandCard() {
    window.open("https://businesscard.zerobrandcard.com", "_blank")
  }

  goContactUs() {
    this.router.navigate(['/contact-us'])
  }

  goRegistry() {
    this.router.navigate(['/register'])
  }

  goPricing() {
    this.router.navigate(['/pricing'])
  }

  goAboutUs() {
    this.router.navigate(['/about-us'])
  }
  
  goHome() {
    this.router.navigate(['/'])
  }

}
