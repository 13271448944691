import { Component, OnInit } from '@angular/core';
import * as $ from "jquery";
declare var $: any;

@Component({
  selector: 'app-complete-registry',
  templateUrl: './complete-registry.component.html',
  styleUrls: ['./complete-registry.component.scss']
})
export class CompleteRegistryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    $(".section-title").html('REGISTRATION');
  }

}
