import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-content-mobile',
  templateUrl: './content-mobile.component.html',
  styleUrls: ['./content-mobile.component.scss']
})
export class ContentMobileComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    //var footerPos = $(".footer-wrapper").offset();

    $(window).scroll(function () {

      if($('.top-menu:visible').length == 1) {
        $(".top-menu").toggle();
        $(".overlay").toggle();
      }

      //var scrollPos = $(window).scrollTop() + $(window).height();

    });

    $(".overlay").on('click',function(){
      // if($('.top-menu:visible').length == 1) {
        $(".top-menu").toggle();
        $(".overlay").toggle();
      // };
    });

  }

}
