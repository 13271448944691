// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://wapi.codezero.app',
  onlyCountries: [] /*["al", "ad", "am", "at", "au", "az", "bd", "br", "bn", "by",
  "be", "ba", "bg", "kh", "ca", "cn", "hr", "cy", "cz", "dk",
  "ee", "fi", "fr", "ge", "de", "gr", "hk", "hu", "is", "in",
  "id", "ie", "it", "jp", "kz", "xk", "lv", "li", "lt", "lu",
  "mo", "my", "mt", "md", "mc", "me", "mm", "nl", "nz", "mk",
  "no", "pk", "ph", "pl", "pt", "ro", "ru", "sm", "rs", "sg",
  "sk", "si", "kr", "es", "se", "ch", "tw", "th", "tr", "ua",
  "gb", "us", "va", "vn","za"
]*/,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
