import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor() {
    // $(window).on('load resize', function () {
    //   if ($(window).width() <= 768) {
    //     window.location.href = "/m/privacy"
    //   }
    // });
  }

  ngOnInit() {
    $(".section-title").html('PRIVACY POLICY');
  }
}
