import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgwWowModule } from 'ngx-wow';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';


import { HeaderComponent } from './layout/header/header.component';
import { ContentComponent } from './layout/content/content.component';
import { FooterComponent } from './layout/footer/footer.component';
import { MenuComponent } from './layout/menu/menu.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { BlackcardComponent } from './pages/blackcard/blackcard.component';
import { PressComponent } from './pages/press/press.component';
import { LandingPageComponent } from './layout/landing-page/landing-page.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { RegistryComponent } from './pages/registry/registry.component';
import { FaqComponent } from './pages/faq/faq.component';
import { CompleteRegistryComponent } from './pages/complete-registry/complete-registry.component';
import { WorkInProgressComponent } from './pages/work-in-progress/work-in-progress.component';

@NgModule({
  declarations: [
    HeaderComponent,
    ContentComponent,
    FooterComponent,
    MenuComponent,
    HomePageComponent,
    BlackcardComponent,
    PressComponent,
    LandingPageComponent,
    TermsComponent,
    PrivacyComponent,
    AboutUsComponent,
    ContactUsComponent,
    RegistryComponent,
    FaqComponent,
    CompleteRegistryComponent,
    WorkInProgressComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgwWowModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxIntlTelInputModule
  ]
})
export class DesktopModule { }
