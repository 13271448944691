import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'zeroweb-new';

  constructor(
    private router: Router
  ) {

  }

  ngOnInit() {
    if (this.router.url === '/login') {
      window.open("https://businesscard.zerobrandcard.com/", "_blank");
    }
  }

}
