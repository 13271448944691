import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var $: any;

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  constructor() {
    // $(window).on('load resize',function(){
    //   if($(window).width() <= 768){
    //     window.location.href = "/m/terms"
    //   }
    // });
   }

  ngOnInit() {
    $(".section-title").html('TERMS AND CONDITIONS');
  }
}
