import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss']
})
export class FooterMobileComponent implements OnInit {

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {
  }

  goZeroBrandCard() {
    window.open("https://businesscard.zerobrandcard.com", "_blank")
  }

  goContactUs() {
    this.router.navigate(['/m/contact-us'])
  }

  goRegistry() {
    this.router.navigate(['/m/register'])
  }

  goPricing() {
    this.router.navigate(['/m/pricing'])
  }

  goWorks() {
    this.router.navigate(['/m/pricing'], { fragment: 'HowItWorks' })
  }

  goFAQ() {
    this.router.navigate(['/m/faq'])
  }

  goAboutUs() {
    this.router.navigate(['/m/about-us'])
  }

  goMissionVision() {
    this.router.navigate(['/m/about-us'], { fragment: 'MissionVision' })
  }

  goPartners() {
    this.router.navigate(['/m/about-us'], { fragment: 'Partners' })
  }

  goPress() {
    this.router.navigate(['/m/about-us'], { fragment: 'Press' })
  }

  goHome() {
    this.router.navigate(['/m/home'])
  }

  goDigital() {
    this.router.navigate(['/m/home'], { fragment: 'GoDigital' })
  }

  goDynamic() {
    this.router.navigate(['/m/home'], { fragment: 'TrulyDynamic' })
  }

  goFully() {
    this.router.navigate(['/m/home'], { fragment: 'FullyDigital' })
  }

  goPrivacy() {
    this.router.navigate(['/m/privacy'])
  }

  goTerms() {
    this.router.navigate(['/m/terms'])
  }

}
