import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  constructor() {

   }

  ngOnInit() {

    $(".toggle-bg").on('click', function() {
      $('.menu-toggle').toggle();

      if ($(".toggle-bg").is(':visible')) {
        $("body").css("overflow-y", "hidden");
      }
      else {
        $("body").css("overflow-y", "scroll");
      }
    });
  }

  toggleMenu() {
    $('.menu-toggle').toggle();

    if ($(".toggle-bg").is(':visible')) {
      $("body").css("overflow-y", "hidden");
    }
    else {
      $("body").css("overflow-y", "scroll");
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  goWebsite() {
    window.open("https://codezero.app", "_blank");
  }

  goHome() {
    window.open("https://zerobrandcard.com");
  }

  goLogin() {
    window.open("https://businesscard.zerobrandcard.com", "_blank");
  }

}
