import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';

import { NgwWowService } from 'ngx-wow';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

import Swal from 'ngx-angular8-sweetalert2';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  apiurl = '';
  loading = false;

  featureDefault = true;
  featureShare = false;
  featureExchange = false;
  featureEcho = false;
  featureFeed = false;
  featureFeaturedContent = false;
  featureSearch = false;
  featureScan = false;
  featureContact = false;
  featureFollowUps = false;
  featureVisitorRegistry = false;
  featureBlackBox = false;
  featureHRMS = false

  showNavigationArrows = false;
  showNavigationIndicators = false;

  aboutDefault = true;
  aboutVision = false;
  aboutPartners = false;

  contactForm: FormGroup;

  constructor(
    private router: Router,
    public http: HttpClient,
    private wowService: NgwWowService,
    private config: NgbCarouselConfig,
    private formBuilder: FormBuilder,
  ) {

    /*$(window).on('load resize',function(){
      if($(window).width() <= 768){
        window.location.href = "/m/home";
      }
    });*/

    this.apiurl = environment.apiUrl;

    config.showNavigationArrows = false;
    config.showNavigationIndicators = true;

    this.wowService.init();
  }

  ngOnInit() {
    $(".section-title").html('HOME');

    $(window).scroll(function () {
      var sec1Pos = $(".sec-1").offset().top;
      var sec1Height = $(".sec-1").outerHeight(true) ;
      var sec2Pos = $(".sec-2").offset().top;
      var sec2Height = $(".sec-2").outerHeight(true);
      var sec3Pos = $(".sec-3").offset().top;
      var sec3Height = $(".sec-3").outerHeight(true);
      var sec4Pos = $(".sec-4").offset().top;
      var sec4Height = $(".sec-4").outerHeight(true);
      //var scrollPos = $(window).scrollTop() + $(window).height();
      var scrollPos = $(window).scrollTop() + 60;

      if (scrollPos > sec1Pos && scrollPos < sec1Pos + sec1Height) {
        $(".section-title").html('HOME');
      }
      else if (scrollPos > sec2Pos && scrollPos < sec2Pos + sec2Height) {
        $(".section-title").html('GO DIGITAL');
      }
      else if (scrollPos > sec3Pos && scrollPos < sec3Pos + sec3Height) {
        $(".section-title").html('TRULY DYNAMIC');
      }
      else if (scrollPos > sec4Pos && scrollPos < sec4Pos + sec4Height) {
        $(".section-title").html('FULLY DIGITAL');
      }
  
    });

    $(".discover-icons").on('click', function () {

      if ($(".discover-icons").find(".active")) {
        $(".discover-icons.active").find(".discover-btn-desc").toggleClass("font-active");
        $(".discover-icons.active").find("img").toggle();
        $(".discover-icons.active").removeClass("active");
      }

      $(this).addClass("active");
      $(this).find(".discover-btn-desc").toggleClass("font-active");
      $(this).find("img").toggle();
    });
  }

  goAppStore() {
    window.open("https://itunes.apple.com/my/app/zero-brandcard/id1433549946?mt=8&utm_source=ios&utm_medium=webpage&utm_campaign=zeroweb", "_blank");
  }

  goGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=tech.zero.app2&utm_source=android&utm_medium=webpage&utm_campaign=zeroweb", "_blank");
  }

  goAppGallery() {
    window.open("https://appgallery.huawei.com/app/C106256461", "_blank");
  }

  goWhiteCard() {
    window.open("/brandcard#PersonalCard", "_self");
  }

  goBlackCard() {
    window.open("/brandcard#BusinessCard", "_self");
  }

  displayFeature(itm) {
    this.featureDefault = false;
    this.featureShare = false;
    this.featureExchange = false;
    this.featureEcho = false;
    this.featureFeed = false;
    this.featureFeaturedContent = false;
    this.featureSearch = false;
    this.featureScan = false;
    this.featureContact = false;
    this.featureFollowUps = false;
    this.featureVisitorRegistry = false;
    this.featureBlackBox = false;
    this.featureHRMS = false;

    if (itm === 'Share') {
      this.featureShare = true;
    }
    if (itm === 'Exchange') {
      this.featureExchange = true;
    }
    if (itm === 'Echo') {
      this.featureEcho = true;
    }
    if (itm === 'Feed') {
      this.featureFeed = true;
    }
    if (itm === 'FeaturedContent') {
      this.featureFeaturedContent = true;
    }
    if (itm === 'Search') {
      this.featureSearch = true;
    }
    if (itm === 'Scan') {
      this.featureScan = true;
    }
    if (itm === 'Contact') {
      this.featureContact = true;
    }
    if (itm === 'FollowUps') {
      this.featureFollowUps = true;
    }
    if (itm === 'VisitorRegistry') {
      this.featureVisitorRegistry = true;
    }
    if (itm === 'BlackBox') {
      this.featureBlackBox = true;
    }
    if (itm === 'HRMS') {
      this.featureHRMS = true;
    }
  }

  goPricing() {
    this.router.navigate(['/pricing'])
  }

  goHowItWorks() {
    this.router.navigate(['/pricing'], { fragment: 'HowItWorks' })
  }

  get formError() {
    return this.contactForm.controls;
  }

  submitContact() {

    let url = this.apiurl + "/v3/contactus";
    let form = this.contactForm;

    if (form.invalid) {
      this.loading = false;
      return;
    }

    this.loading = true;

    let params = {
      name: form.value.name,
      email: form.value.email,
      subject: form.value.subject,
      message: form.value.message
    }

    this.http.post(url, params).subscribe((res: any) => {

      if (res.status == "OK") {
        Swal.fire({
          icon: 'success',
          title: 'Thank you for contacting us',
          html: "We will get back to you as soon as possible.",
          confirmButtonColor: '#7DEDBB',
          allowOutsideClick: false
        }).then((result) => {
          this.loading = false;
          this.contactForm.reset();
        });
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops!',
          html: "Something went wrong. Please try again later.",
          confirmButtonColor: '#7DEDBB',
          allowOutsideClick: false
        }).then((result) => {
          this.loading = false;
          this.contactForm.reset();
        });
      }
    });
  }

}
