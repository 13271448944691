import { Injectable } from '@angular/core';
import { HttpClient,HttpErrorResponse,HttpHeaders  } from '@angular/common/http';
import { Observable } from 'rxjs';


//import { Observable } from 'rxjs/Observable';
//import 'rxjs/add/operator/map';
//import 'rxjs/add/operator/catch';

//import { Observable, throwError } from 'rxjs';
//import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private token:string;
  private httpOptions:any;
  //private apiurl:string = "https://dev.codezero.app";
  private tokenkey = "_authtoken";

  constructor(public http: HttpClient) {
    //localStorage.setItem('authtoken', token.access_token);

  }


  /*get(url, param?):Observable {
    if (param) {
      this.http.get(url, param).subscribe(data=>{
        return data
      },

      err=>{
        this.printError(err);
      });
    }
    else {
      this.http.get(url).subscribe(data=>{
        return data
      },

      err=>{
        this.printError(err);  
      });
    }
  }*/


  setHttpOntion(token:string) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        //'Authorization': 'bearer ' + token
      })
    };

    return this.httpOptions
  }


  async get1(url:string,param:object) {
      let data = this.setParams(param);
      //url += '?' + param;
      
      return await this.http.get(url,data).toPromise();
  }


  public get(url:string,param:object) {
    let data = this.setParams(param);
    //url += '?' + param;
    return this.http.get(url,data).toPromise();
    
  }



  public get2(url:string,param:object):Observable<any> {
    let data = this.setParams(param);
    //url += '?' + param;
    return this.http.get(url,data);
  }



  post(url:string, param:object):any {
    return this.http.post(url, param,this.getHttpOptions());
  }

  async post1(url:string, param:object) {
    return await this.http.post(url, param,this.getHttpOptions()).toPromise();
  }

  uploadFile(url:string,data:FormData) {
    /*let httpOptions = {
      responseType: "blob", 
      reportProgress: true, 
      observe: "events",
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Authorization': 'bearer ' + this.token
      }),
    };*/

    return this.http.post(url , data, {
       reportProgress: true, observe: "events", headers: new HttpHeaders(
        {  'Accept': 'application/json',
        'Authorization': 'bearer ' + this.token },
      )
    });

    //return this.http.post(url, data,httpOptions);
  }

  /* Method Delete */
  delete(url, param) {
    let data = this.setParams(param);

    return this.http.delete(url,data);
  }


  /* Method Put */
  put(url,param) {
    let data = this.setParams(param);

    return this.http.put(url,data);
  }


  printError(err:HttpErrorResponse) {
    if (err.error instanceof Error) {
      // console.log(err.error.message);
    } else {
      // console.log(JSON.stringify(err));
    }
  }


  getError(err:HttpErrorResponse) {
    if (err.error instanceof Error) {
      return err.error;
    } else {
      return null;
    }
  }

  /*call(url, param, options?) {
    if (options) {
      return this.http.post(url, param, options).subscribe((res) => {
        return res;
      }).catch((e) => {
        return Observable.throw(e);
      });
    } else {
      return this.http.post(url, param).map((res: Response) => {
        return res.json();
      }).catch((e) => {
        return Observable.throw(e);
      });
    }
  }*/

  setParams(param:Object) {
    if (param) {
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/json',
            //'Authorization': 'bearer ' + this.getToken(),
          },
          ),
          params: param,
          
        }

        return httpOptions;
    }

    return this.httpOptions;
  }

  public getHttpOptions() {
    //this.setHttpOntion(this.getToken());
    return this.httpOptions;
  }
}
