import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from "@angular/platform-browser";

//import { AuthService } from '../../services/Auth/auth.service';
import { UserService } from '../../../services/user/user.service';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

import Swal from 'sweetalert2';

@Component({
  selector: 'app-visitor-landing-page',
  templateUrl: './visitor-landing-page.component.html',
  styleUrls: ['./visitor-landing-page.component.scss']
})
export class VisitorLandingPageComponent implements OnInit {

  contactusForm: FormGroup;
  user: any;

  loading = false;

  constructor(
    private fb: FormBuilder,
    //private authService: AuthService,
    private userService:UserService,
    private router:Router,
    private titleService: Title,
  ) {

    this.titleService.setTitle("Visitor Registry - ZERO BrandCard™");

    this.contactusForm = this.fb.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
      subject: [null, Validators.required],
      message: [null, Validators.required]
    });

  }

  ngOnInit() {
    $(".bottom-menu-btn").hover(function () {
      $(this).find("span").css("visibility", "visible");
    }, function () {
      $(this).find("span").css("visibility", "hidden");
    });
  }

  toggleMenu() {
    // $('.menu-toggle').toggle();

    // if ($(".toggle-bg").is(':visible')) {
    //   $("body").css("overflow-y", "hidden");
    // }
    // else {
    //   $("body").css("overflow-y", "scroll");
    // }
    $(".bottom-menu").fadeToggle();
    $(".menu-title").fadeToggle();
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  setUp() {
    window.open('https://businesscard.zerobrandcard.com', '_blank');
  }

  goWhatsapp() {
    window.open('Https://wa.me/60183503848', '_blank');
  }

  contactUs() {
    window.open('https://zerobrandcard.com/visitor-registry#ContactUs');
  }

  goPrice() {
    this.router.navigate(['/pricing']);
  }

  get contactusError() {
    return this.contactusForm.controls;
  }

  onSubmit() {

    let form = this.contactusForm;

    if (form.invalid) {
      this.loading = false;
      return;
    }

    this.loading = true;

    let data = {
      name: form.value.name,
      email: form.value.email,
      subject: form.value.subject,
      message: form.value.message
    }

    this.userService.contactus(data).then((res: any) => {
      // console.log(JSON.stringify(res));
      if (res.status == 'OK') {
        Swal.fire({
          type: 'success',
          title: 'Thank you for contacting us.',
          html: "We will get back to you as soon as possible.",
          confirmButtonColor: '#93E8F1',
          allowOutsideClick: false
        }).then((result) => {
          this.loading = false;
          this.contactusForm.reset();
          this.contactusForm.get("email").setValue(this.user.email);
        });
      }
    });


  }

  goWebsite() {
    window.open('https://codezero.app/',"_blank");
  }

  goLogin() {
    window.open("https://businesscard.zerobrandcard.com","_self")
  }

  goZeroBrandCard() {
    window.open("https://businesscard.zerobrandcard.com","_self")
  }

  goFeatures() {
    window.open("/#Features","_self")

  }

  goBusiness() {
    window.open("/#Business","_self")

  }
  goAboutUs() {
    window.open("/#AboutUs","_self")
  }

  goTutorial() {
    window.open("/#Tutorial","_self")

  }
  goEnvironment() {
    window.open("/#Environment","_self")

  }
  goContact() {
    window.open("/#ContactUs","_self")
  }

  goVisitorRegistry() {
    window.open("/visitor-registry", "_self");
  }

  goAppStore() {
    window.open("https://itunes.apple.com/my/app/zero-brandcard/id1433549946?mt=8&utm_source=ios&utm_medium=webpage&utm_campaign=zeroweb", "_blank");

  }

  goGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=tech.zero.app2&utm_source=android&utm_medium=webpage&utm_campaign=zeroweb", "_blank");

  }

  goAppGallery() {
    window.open("https://appgallery.huawei.com/app/C106256461", "_blank");
  }

  goHome() {
    window.open("/","_self")
  }

}
