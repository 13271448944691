import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {

  constructor() {
    $(window).on('load resize',function(){
      if($(window).width() <= 768){
        window.location.href = "/m/press"
      }
    });
  }

  ngOnInit() {

    $(".bottom-menu-btn").hover(function(){
      $(this).find("span").css("visibility","visible");
    },function() {
      $(this).find("span").css("visibility","hidden");
    });


  }

  toggleMenu() {
    $(".bottom-menu").fadeToggle();
    $(".menu-title").fadeToggle();
  }

  goHome() {
    window.open("/","_self");
  }

  goBrandCard() {
    window.open("#BrandCard","_self");
  }

  goFeatures() {
    window.open("#Features","_self");
  }

  goBusiness() {
    window.open("#Business","_self");
  }

  goAboutUs() {
    window.open("#AboutUs","_self");
  }

  goTutorial() {
    window.open("#Tutorial","_self");
  }

  goEnvironment() {
    window.open("#Environment","_self");
  }

  goContact() {
    window.open("#ContactUs","_self");
  }

  goVisitorRegistry() {
    window.open("https://zerobrandcard.com/","_blank");
  }

  goAppStore() {
    window.open("https://itunes.apple.com/my/app/zero-brandcard/id1433549946?mt=8","_blank");
  }

  goGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=tech.zero.app2","_blank");
  }

  goAppGallery() {
    window.open("https://appgallery.huawei.com/app/C106256461", "_blank");
  }

  goPricing() {
    window.open("/pricing", "_self");
  }

  goLogin() {
    window.open("https://businesscard.zerobrandcard.com","_blank");
  }

}
