import { Injectable } from '@angular/core';
import {HttpService} from '../../core/lib/http.service'
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  apiurl = environment.apiUrl;

  constructor(private httpservice: HttpService) { 
    
  }

  contactus(data:object) {
    // console.log("token: " + data);
    return this.httpservice.post1(this.apiurl + '/v3/contactus',data);
  }
}
