import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})
export class HeaderMobileComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    $(".menu-click").on('click', function () {
      $(".top-menu").toggle();
      $(".overlay").toggle();
    });

    $(".logo-icon").on('click',function(){
      if($('.top-menu:visible').length == 1) {
        $(".top-menu").toggle();
        $(".overlay").toggle();
      };
    });

  }

  goZeroBrandCard() {
    window.open("https://businesscard.zerobrandcard.com/", "_blank");
  }

  goHome() {
    this.router.navigate(['/m/home']);
  }

  goContactUs() {
    this.router.navigate(['/m/contact-us']);
  }

  goRegister() {
    this.router.navigate(['/m/register']);
  }

  goPricing() {
    this.router.navigate(['/m/pricing']);
  }

  goAboutUs() {
    this.router.navigate(['/m/about-us']);
  }

  toggleMenu() {
    $(".top-menu").fadeToggle();
    $(".overlay").fadeToggle();
  }

}
