import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-pricing-m',
  templateUrl: './pricing-m.component.html',
  styleUrls: ['./pricing-m.component.scss']
})
export class PricingMComponent implements OnInit {

  constructor(
    private router: Router,
    private auth: AuthGuard,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Pricing - ZERO BrandCard™");
  }

  ngOnInit() {
  }

  goFAQ() {
    if (this.auth.isMobile()) {
      this.router.navigate(['/m/faq'])
    } else {
      this.router.navigate(['/faq'])
    }
  }

  goRegistry() {
    if (this.auth.isMobile()) {
      this.router.navigate(['/m/register'])
    } else {
      this.router.navigate(['/register'])
    }
  }

  goDownload() {
    window.open("https://web.codezero.app/appdownload", "_blank");
  }

  goAppStore() {
    window.open("https://itunes.apple.com/my/app/zero-brandcard/id1433549946?mt=8&utm_source=ios&utm_medium=webpage&utm_campaign=zeroweb", "_blank");
  }

  goGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=tech.zero.app2&utm_source=android&utm_medium=webpage&utm_campaign=zeroweb", "_blank");
  }

  goAppGallery() {
    window.open("https://appgallery.huawei.com/app/C106256461", "_blank");
  }


  wcTutorial() {
    window.open("https://drive.google.com/file/d/17fVjBNPkU8YHZN9yeRn0VZyrQYdbyZoh/view?usp=sharing", "_blank");
  }

  bcTutorial() {
    window.open("https://drive.google.com/file/d/1YPLQ84W-1Qhp3_DzP5BwP3jb0mhxoMsO/view?usp=sharing", "_blank");
  }

  downloadTutorial() {
    window.open("https://drive.google.com/file/d/1_MDcFn7P9jBeF0HmFBx3q3eDjFui9_gM/view?usp=sharing", "_blank");
  }

}
