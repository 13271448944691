import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'ngx-angular8-sweetalert2';
import { environment } from 'src/environments/environment';

import * as $ from "jquery";
import { Title } from '@angular/platform-browser';
declare var $: any;

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup;
  loading = false;
  apiurl = environment.apiUrl;
  isSubmit = false;

  constructor(
    private fB: FormBuilder,
    public http: HttpClient,
    private titleService: Title,
  ) {
    this.titleService.setTitle("Contact Us - ZERO BrandCard™");

    this.contactForm = this.fB.group({
      name: [null, Validators.required],
      title: [null, Validators.required],
      email: [null, [Validators.required, Validators.pattern("^[_A-Za-z0-9-\\+]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$")]],
      mobile: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      co_name: [null, Validators.required],
      subject: ['', Validators.required],
      message: [null, [Validators.required, Validators.maxLength(2000)]],
    });
  }


  ngOnInit() {
    $(".section-title").html('CONTACT US');
  }

  get formError() {
    return this.contactForm.controls;
  }

  submitContact() {
    this.isSubmit = true;
    
    if (this.contactForm.invalid) {
      this.loading = false;
      return;
    }

    this.loading = true;
    let url = this.apiurl + "/v3/contactus";
    const form = this.contactForm.value;

    let subjectValue;
    if (form.subject === '1') {
      subjectValue = 'Enquiries'
    } else if (form.subject === '2') {
      subjectValue = 'Troubleshooting'
    } else if (form.subject === '3') {
      subjectValue = 'Others'
    }

    const params = {
      name: form.name,
      email: form.email,
      title: form.title,
      message: form.message,
      mobile: form.mobile,
      company: form.co_name,
      subject: subjectValue
    }

    this.http.post(url, params).subscribe((res: any) => {

      if (res.status == "OK") {
        Swal.fire({
          icon: 'success',
          title: 'Thank you for contacting us',
          html: "We will get back to you as soon as possible.",
          confirmButtonColor: '#7DEDBB',
          allowOutsideClick: false
        }).then((result) => {
          this.loading = false;
          this.isSubmit = false;
          this.contactForm.reset();
        });
      }
      else {
        Swal.fire({
          icon: 'error',
          title: 'Oops!',
          html: "Something went wrong. Please try again later.",
          confirmButtonColor: '#7DEDBB',
          allowOutsideClick: false
        }).then((result) => {
          this.loading = false;
          // this.contactForm.reset();
        });
      }
    });

  }
}
