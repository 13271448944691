import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-blackcard-m',
  templateUrl: './blackcard-m.component.html',
  styleUrls: ['./blackcard-m.component.scss']
})
export class BlackcardMComponent implements OnInit {

  featuredcontent = true;
  notes = false;
  quicklinks = false;

  constructor() {

    $(window).on('load resize',function(){
      if($(window).width() > 768){
        window.location.href = "/brandcard";
      }
    });

    $(".bottom-menu-btn").hover(function(){
      $(this).find("span").css("visibility","visible");
    },function() {
      $(this).find("span").css("visibility","hidden");
    });
  }

  ngOnInit() {

    $(".feature-icons").on('click',function(){

      if ($(".feature-icons").find(".active")) {
        $(".feature-icons.active").find(".feature-btn-desc").removeClass("font-visible");
        $(".feature-icons.active").find("img").toggle();
        $(".feature-icons.active").removeClass("active");
      }

      $(this).addClass("active");
      $(this).find("img").toggle();
      $(this).find(".feature-btn-desc").addClass("font-visible");
    });

    setInterval(function() {
      /*$('.visitor-copy').fadeOut("slow",
        $(".visitor-copy").toggle()
      );*/
      $(".visitor-copy").toggle()
    },5000)

  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  toggleMenu() {
    $(".bottom-menu").fadeToggle();
  }

  goBrandCard() {
    window.open("/m/home#BrandCard","_self");
  }

  goFeatures() {
    window.open("/m/home#Features","_self");
  }

  goBusiness() {
    window.open("/m/home#Business","_self");
  }

  goAboutUs() {
    window.open("/m/home#AboutUs","_self");
  }

  goTutorial() {
    window.open("/m/home#Tutorial","_self");
  }

  goEnvironment() {
    window.open("/m/home#Environment","_self");
  }

  goContact() {
    window.open("/m/home#ContactUs","_self");
  }

  goVisitorRegistry() {
    window.open("https://zerobrandcard.com/","_blank");
  }

  goAppStore() {
    window.open("https://itunes.apple.com/my/app/zero-brandcard/id1433549946?mt=8","_blank");
  }

  goGooglePlay() {
    window.open("https://play.google.com/store/apps/details?id=tech.zero.app2","_blank");
  }

  goAppGallery() {
    window.open("https://appgallery.huawei.com/app/C106256461", "_blank");
  }

  downloadTutorial() {
    window.open("https://drive.google.com/file/d/1GRXY3soitGaaqJPQzcQyvinSw2TsVKiC/view","_blank");
  }

  wcTutorial() {
    window.open("./assets/pdf/personalcard_tutorial.pdf","_blank");
  }

  bcTutorial() {
    window.open("./assets/pdf/businesscard_tutorial.pdf","_blank");
  }

  goZeroBrandCard() {
    window.open("https://businesscard.zerobrandcard.com/","_blank");
  }

  toggleImg() {
    $(this).find("img").toggle();
  }

  displayFeature() {
    this.featuredcontent = true;
    this.notes = false;
    this.quicklinks = false;
  }

  displayNotes() {
    this.featuredcontent = false;
    this.notes = true;
    this.quicklinks = false;
  }

  displayLinks() {
    this.featuredcontent = false;
    this.notes = false;
    this.quicklinks = true;
  }

}
