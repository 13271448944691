import { Component, OnInit } from '@angular/core';

import * as $ from "jquery";
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit {

  constructor() { }

  ngOnInit() {

    $(".bottom-menu-btn").hover(function(){
      $(this).find("span").css("visibility","visible");
    },function() {
      $(this).find("span").css("visibility","hidden");
    });

  }

  toggleMenu() {
    $(".bottom-menu").toggle();
  }

  goBrandCard() {
    $(".section-1")[0].scrollIntoView({
      behavior: "smooth",
    });
  }

}
