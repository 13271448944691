import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgwWowModule } from 'ngx-wow';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FooterMobileComponent } from './layout/footer-mobile/footer-mobile.component';
import { ContentMobileComponent } from './layout/content-mobile/content-mobile.component';
import { MenuMobileComponent } from './layout/menu-mobile/menu-mobile.component';
import { HomePageMComponent } from './pages/home-page-m/home-page-m.component';
import { BlackcardMComponent } from './pages/blackcard-m/blackcard-m.component';
import { PressMComponent } from './pages/press-m/press-m.component';
import { TermsMComponent } from './pages/terms-m/terms-m.component';
import { PrivacyMComponent } from './pages/privacy-m/privacy-m.component';
import { HeaderMobileComponent } from './layout/header-mobile/header-mobile.component';
import { AboutUsMComponent } from './pages/about-us-m/about-us-m.component';
import { PricingMComponent } from './pages/pricing-m/pricing-m.component';

@NgModule({
  declarations: [
    FooterMobileComponent,
    ContentMobileComponent,
    MenuMobileComponent,
    HomePageMComponent,
    BlackcardMComponent,
    PressMComponent,
    TermsMComponent,
    PrivacyMComponent,
    HeaderMobileComponent,
    AboutUsMComponent,
    PricingMComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgwWowModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class MobileModule { }
