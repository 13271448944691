import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import * as $ from "jquery";
declare var $: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle("About Us - ZERO BrandCard™");
  }

  ngOnInit() {
    $(".section-title").html('ABOUT US');
    $(window).scroll(function () {
      var sec1Pos = $(".sec-1").offset().top;
      var sec1Height = $(".sec-1").outerHeight(true) ;
      // var sec2Pos = $(".sec-2").offset().top;
      // var sec2Height = $(".sec-2").outerHeight(true);
      var sec3Pos = $(".sec-3").offset().top;
      var sec3Height = $(".sec-3").outerHeight(true);
      var sec4Pos = $(".sec-4").offset().top;
      var sec4Height = $(".sec-4").outerHeight(true);

      var scrollPos = $(window).scrollTop() + 60;

      if (scrollPos > sec1Pos && scrollPos < sec1Pos + sec1Height) {
        $(".section-title").html('ABOUT US');
      }
      // else if (scrollPos > sec2Pos && scrollPos < sec2Pos + sec2Height) {
      //   $(".section-title").html('MISSION & VISION');
      // }
      else if (scrollPos > sec3Pos && scrollPos < sec3Pos + sec3Height) {
        $(".section-title").html('PARTNERS');
      }
      else if (scrollPos > sec4Pos && scrollPos < sec4Pos + sec4Height) {
        $(".section-title").html('PRESS');
      }
    });
  }

}
