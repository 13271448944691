import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContentComponent } from './desktop/layout/content/content.component';
import { HomePageComponent } from './desktop/pages/home-page/home-page.component';
import { BlackcardComponent } from './desktop/pages/blackcard/blackcard.component';
import { PressComponent } from './desktop/pages/press/press.component';
import { TermsComponent } from './desktop/pages/terms/terms.component';
import { PrivacyComponent } from './desktop/pages/privacy/privacy.component';

import { ContentMobileComponent } from './mobile/layout/content-mobile/content-mobile.component';
import { HomePageMComponent } from './mobile/pages/home-page-m/home-page-m.component';
import { BlackcardMComponent } from './mobile/pages/blackcard-m/blackcard-m.component';
import { PressMComponent } from './mobile/pages/press-m/press-m.component';
import { TermsMComponent } from './mobile/pages/terms-m/terms-m.component';
import { PrivacyMComponent } from './mobile/pages/privacy-m/privacy-m.component';
import { LandingPageComponent } from './desktop/layout/landing-page/landing-page.component';
import { VisitorLandingPageComponent } from './desktop/pages/visitor-landing-page/visitor-landing-page.component';
import { PricingPageComponent } from './desktop/pages/pricing-page/pricing-page.component';
import { CtosVerifiedComponent } from './desktop/pages/ctos-verified/ctos-verified.component';
import { CtosLandingPageComponent } from './desktop/pages/ctos-landing-page/ctos-landing-page.component';
import { AuthGuard } from './core/guards/auth.guard';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './desktop/pages/about-us/about-us.component';
import { ContactUsComponent } from './desktop/pages/contact-us/contact-us.component';
import { RegistryComponent } from './desktop/pages/registry/registry.component';
import { FaqComponent } from './desktop/pages/faq/faq.component';
import { CompleteRegistryComponent } from './desktop/pages/complete-registry/complete-registry.component';
import { AboutUsMComponent } from './mobile/pages/about-us-m/about-us-m.component';
import { PricingMComponent } from './mobile/pages/pricing-m/pricing-m.component';
import { WorkInProgressComponent } from './desktop/pages/work-in-progress/work-in-progress.component';

const routes: Routes = [
  {
    path: '',
    component: ContentComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        component: HomePageComponent
      },
      {
        path: 'brandcard',
        component: BlackcardComponent
      },
      {
        path: 'press',
        component: PressComponent
      },
      {
        path: 'terms',
        component: TermsComponent
      },
      {
        path: 'privacy',
        component: PrivacyComponent
      },
      {
        path: 'login',
        component: AppComponent
      },
      {
        path: 'visitor-registry',
        component: VisitorLandingPageComponent
      },
      {
        path: 'pricing',
        component: PricingPageComponent
      },
      {
        path: 'about-us',
        component: AboutUsComponent
      },
      {
        path: 'contact-us',
        component: ContactUsComponent
      },
      {
        path: 'register',
        component: RegistryComponent
      },
      {
        path: 'complete-register',
        component: CompleteRegistryComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: 'wip',
        component: WorkInProgressComponent
      },
    ]
  },
  {
    path: 'm',
    component: ContentMobileComponent,
    children: [
      {
        path: 'home',
        component: HomePageMComponent
      },
      {
        path: 'brandcard',
        component: BlackcardMComponent
      },
      {
        path: 'press',
        component: PressMComponent
      },
      {
        path: 'terms',
        component: TermsMComponent
      },
      {
        path: 'privacy',
        component: PrivacyMComponent
      },
      {
        path: 'visitor-registry',
        component: VisitorLandingPageComponent
      },
      {
        path: 'pricing',
        component: PricingMComponent
      },
      {
        path: 'about-us',
        component: AboutUsMComponent
      },
      {
        path: 'contact-us',
        component: ContactUsComponent
      },
      {
        path: 'register',
        component: RegistryComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: 'complete-register',
        component: CompleteRegistryComponent
      },
      {
        path: 'wip',
        component: WorkInProgressComponent
      },
    ]
  },

  {
    path: '',
    component: LandingPageComponent,
    children: [
      {
        path: 'ctos-verified-company1',
        component: CtosVerifiedComponent
      },
      {
        path: 'ctos-verified-company',
        component: CtosLandingPageComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
    scrollOffset: [0, 48],
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
